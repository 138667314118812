import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import Loader from 'components/Common/Loader';
import { useCreateCategoryMutation, useUpdateCategoryMutation } from 'api/categoriesApi';
import {
  StyledModal,
  StyledTitle,
  StyledLabel,
  LabelInputWrapper,
  StyledInput,
  FooterWrapper,
  StyledOkButton,
  StyledCancelButton,
  ButtonsWrapper,
  StyledError,
} from './styles';

const CategoryModal = ({
  isCreateModal,
  setIsCreateModal,
  editCategory,
  data,
  setEditCategory,
}) => {
  const [category, setCategory] = useState('');
  const [error, setError] = useState();

  // create Category
  const [createCatgory, { isLoading }] = useCreateCategoryMutation();

  // update Category
  const [updateCategory, { isLoading: updateLoading }] = useUpdateCategoryMutation();

  useEffect(() => {
    if (editCategory) {
      setCategory(data?.name);
    }
  }, [data]);

  const saveCategory = async () => {
    if (!category) {
      setError('Name is required');
      return;
    }

    try {
      const res = editCategory
        ? await updateCategory({ id: data?.id, name: category })
        : await createCatgory(category);

      if (res.error) {
        setError(res.error.data?.message);
        return;
      }

      message.success(`Category ${editCategory ? 'updated' : 'created'} successfully`);
      setIsCreateModal(false);
      setEditCategory(false);
    } catch (err) {
      console.error('An error occurred:', err);
      setError('An unexpected error occurred. Please try again.');
    }
  };

  const handleChange = (e) => {
    if (error) {
      setError('');
    }
    setCategory(e.target.value);
  };

  return (
    <StyledModal
      title={<StyledTitle>{editCategory ? 'Edit' : 'Create'} Category</StyledTitle>}
      open={isCreateModal}
      footer={
        <FooterWrapper>
          <ButtonsWrapper>
            <StyledCancelButton size="large" type="text" onClick={() => setIsCreateModal(false)}>
              Cancel
            </StyledCancelButton>
            <StyledOkButton size="large" onClick={saveCategory}>
              Ok
            </StyledOkButton>
          </ButtonsWrapper>
        </FooterWrapper>
      }
    >
      {error && <StyledError>*&nbsp;{error} </StyledError>}

      <LabelInputWrapper>
        <StyledLabel>Category Name</StyledLabel>
        <StyledInput size="large" value={category} onChange={handleChange} />
      </LabelInputWrapper>
      <Loader isLoading={isLoading || updateLoading} />
    </StyledModal>
  );
};

export default CategoryModal;
