import styled from 'styled-components';

export const Container = styled.div`
  .drop-area {
    position: relative;
    width: 100%;
    height: 325px;
    border-radius: 12px;
    background: #dbdbdb;
    cursor: pointer;
    text-align: center;
    max-width: 325px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const IconBox = styled.div`
  height: 170px;
`;

export const SelectedImage = styled.img`
  max-width: 100%;
  height: auto;
  max-height: 325px !important;
`;

export const ImageIcon = styled.img`
  width: 118px;
  height: 110px;
  margin-top: 60px;
  object-fit: contain;
`;

export const DropText = styled.p`
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: 0.4px;
  text-align: center;
  color: #495057;
  margin-top: 35px;
  span {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.94;
    letter-spacing: 0.32px;
    text-align: left;
    color: #495057;
    .underline-image-text {
      font-weight: bold;
      text-decoration: underline;
    }
  }
`;

export const ThumbnailsSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  max-width: 325px;
`;

export const Thumbnails = styled.div`
  width: 19%;
  height: 53px;
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &.thumbnails-click {
    border: solid 2px #02b183;
  }
  .delete-image-icon {
    position: absolute;
    top: 1px;
    right: 3px;
    font-size: 12px;
    color: red;
    cursor: pointer;
  }
`;

export const ThumbnailImage = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

export const ThumbnailsBackground = styled.div`
  background-color: #dbdbdb;
  height: 100%;
  width: 100%;
  border-radius: 5px;
`;

export const MessageContainer = styled.div`
  color: #dc3545;
  text-align: center;
  margin-top: 1.5rem;
  max-width: 325px;
`;

export const ImageUploadInstruction = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: 0.28px;
  text-align: left;
  color: #000000;
  max-width: 325px;
`;
